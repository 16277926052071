import { useLocation } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { postReq } from "../../../Services/Mutation/Mutation";
import LogoutIcon from "../../../Assets/images/logout.svg";
import queryString from "query-string";
import { Select } from "antd";
import { getReq } from "../../../Services/Query/Query";
import { useState } from "react";
import { toast } from "sonner";

export const BlacklistUser = ({ showDialog, setShowDialog }) => {
  const { search } = useLocation();
  const { zin } = queryString.parse(search);
  const queryClient = useQueryClient();
  const [selectedReason, setSelectedReason] = useState([]);

  const handleChange = (value) => {
    setSelectedReason([value]);
  };

  // Queries & Mutation
  const { data } = useQuery({
    queryKey: [
      "get-blacklist_reasons",
      { route: `vendors/fetch_tags/`, params: {} },
    ],
    queryFn: getReq,
  });

  const mutation = useMutation({
    mutationFn: postReq,
  });

  const handleSubmit = () => {
    mutation
      .mutateAsync({
        payload: {
          type: "blacklist",
          reason: selectedReason,
        },
        route: `/vendors/user/${zin}/`,
      })
      .then((res) => {
        if (res.data.success) {
          queryClient.refetchQueries(["get-single-use"]);
          setShowDialog(!showDialog);
          toast.success("User blacklisted successfully");
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="w-[100%]">
      <div>
        <img src={LogoutIcon} className="mx-auto" alt="" />
        <p className="text-[24px] text-[#0F172A] font-cereal_bold text-center my-[1rem]">
          Blacklist User
        </p>
        <p className="text-[#222222] font-cereal text-[12px] text-center mb-2">
          Are you sure you want to blacklist this user?
          {/* Select reason to blacklist this user */}
        </p>

        <Select
          // mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Please select reason to blacklist this user"
          // defaultValue={['a10', 'c12']}
          onChange={handleChange}
          options={
            data?.data?.tags.map((type) => {
              return {
                value: type?.name,
                label: type?.name,
              };
            }) ?? []
          }
        />
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={!selectedReason?.length || mutation.isPending}
          className="h-[54px] disabled:cursor-not-allowed w-[100%] bg-danger text-secondary rounded-[4px] font-cereal_medium text-[14px] my-[1.5rem]"
        >
          {mutation.isPending ? <LoadingOutlined /> : " Proceed"}
        </button>
        <p
          className="text-[#222222] font-cereal text-[12px] text-center mb-5 cursor-pointer"
          onClick={() => setShowDialog(!showDialog)}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};
