/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CompletedIcon from "../../../Assets/images/completed.svg";
import Logo from "../../../Assets/images/logo.png";
import RouteGuard from "../../../Util/RouteGuard";

const BusinessVerificationLayout = () => {
  const location = useLocation();
  const [path, setPath] = useState(0);
  const [stages, setStages] = useState([
    {
      route: "/business-onboarding/information",
      stage: "Basic business information",
      isComplete: false,
      step: 1,
    },
    {
      route: "/business-onboarding/verification",
      stage: "Business verification",
      isComplete: false,
      step: 2,
    },
    {
      route: "/business-onboarding/director",
      stage: "Director's information",
      isComplete: false,
      step: 3,
    },
    {
      route: "/business-onboarding/data-preference",
      stage: "Data Preference",
      isComplete: false,
      step: 4,
    },
  ]);

  useEffect(() => {
    if (location.pathname === stages[1].route) {
      const stage = [...stages];
      stage[0].isComplete = true;
      setStages(stage);
    } else if (location.pathname === stages[2].route) {
      const stage = [...stages];
      stage[0].isComplete = true;
      stage[1].isComplete = true;
      setStages(stage);
    } else if (location.pathname === stages[3].route) {
      const stage = [...stages];
      stage[0].isComplete = true;
      stage[1].isComplete = true;
      stage[2].isComplete = true;
      setStages(stage);
    } else if (location.pathname === stages[0].route) {
    }
    setPath(location.pathname);
  }, [location]);

  return (
    <RouteGuard>
      {/* <div className="flex items-center justify-between px-[2rem] h-[70px] absolute z-10 top-0 right-0 left-0 bg-secondary shadow-sm">
        <div></div>
        <div className="flex items-center gap-4">
          <Avatar>{user?.name[0]}</Avatar>
          <h2>{user?.name}</h2>
        </div>
      </div> */}
      <div className="block lg:flex items-start h-[100vh]">
        <div className="bg-[#F0F3F5] w-[100%] hidden lg:flex items-center justify-center h-[100vh]">
          <div className="max-w-[450px]">
            <div className="flex justify-center">
              <img src={Logo} className="w-[120px]" alt="" />
            </div>
            <h3 className="text-[32px] text-center font-cereal_medium text-[#272E35] my-[1.2rem]">
              Before you continue, tell us about your business
            </h3>
            <p className="text-center text-[#272E35] font-cereal opacity-[0.7] text-[16px] mb-[2rem]">
              Information you provide will assist us in getting you the right customers.
            </p>
            {stages.map((stage, index) => (
              <div className="bg-[#ffffff] flex items-center gap-4 p-[1rem] mt-[1rem]" key={index}>
                {stage?.isComplete ? (
                  <img src={CompletedIcon} alt="" />
                ) : (
                  <span
                    className={`rounded-[4px] ${
                      path === stage.route ? "bg-[#E5EFFF]" : "bg-[#F0F3F5]"
                    } px-[1rem] py-[.6rem] text-[14px] font-cereal_bold`}
                  >
                    {index + 1}
                  </span>
                )}
                <p className="text-[16px] font-cereal_medium">{stage?.stage}</p>
              </div>
            ))}
          </div>
        </div>
        <Outlet {...{ stages, setStages }} />
      </div>
    </RouteGuard>
  );
};

export default BusinessVerificationLayout;
