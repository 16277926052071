import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { postReq } from "../../../Services/Mutation/Mutation";
// import LogoutIcon from "../../../Assets/images/logout.svg";
// import { IoCloseCircleOutline } from "react-icons/io5";
import queryString from "query-string";
import { toast } from "sonner";

export const RemoveUserFromBlacklist = ({ showDialog, setShowDialog }) => {
  const { search } = useLocation();
  const { zin } = queryString.parse(search);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: postReq,
  });

  const handleSubmit = () => {
    mutation
      .mutateAsync({
        payload: {
          type: "unblacklist"
        },
        route: `/vendors/user/${zin}/`,
      })
      .then((res) => {
        if(res.data.success){
            queryClient.refetchQueries(["get-single-use"]);
            setShowDialog(!showDialog);
            toast.success("User removed from blacklist successfully")
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="w-[100%] py-4">
      <div>
        {/* <img src={LogoutIcon} className="mx-auto" alt="" /> */}
        {/* <div className=" my-[1rem] flex justify-between">
        <p className="text-[20px] text-[#222222] font-cereal_medium ">Confirm Removal</p>
        <IoCloseCircleOutline className="cursor-pointer" size={30} onClick={() => setShowDialog(!showDialog)} />
        </div> */}
        <p className="text-[#222222] font-cereal text-[20px] text-center mb-2">
          Do you want to remove this user from blacklist?
          {/* Select reason to blacklist this user */}
        </p>

        <button
          type="submit"
          onClick={handleSubmit}
          className="h-[54px] disabled:cursor-not-allowed w-[100%] bg-[#0166FF] text-secondary rounded-[4px] font-cereal_medium text-[14px] my-[1.5rem]"
        >
          {mutation.isPending ? <LoadingOutlined /> : "Yes, Remove from blacklist"}
        </button>
        <p
          className="text-[#222222] font-cereal text-[12px] text-center mb-5 cursor-pointer"
          onClick={() => setShowDialog(!showDialog)}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};
